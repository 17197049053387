import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { CoreModule } from 'src/app/core/core.module';
import { GlobalHttpInterceptorService } from 'src/app/core/services/global-http-interceptor.service';
import { GlobalErrorHandlerService } from 'src/app/core/services/global-error-handler.service';
import { LoginComponent } from 'src/app/login/login.component';
import { MultiTranslateHttpLoader } from 'src/app/shared/util/custom-translate.service';
import { DragulaModule } from 'ng2-dragula';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { ToastrModule } from 'ngx-toastr';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { NavbarModule } from './shared/navbar/navbar.module';
import { ModuleNotAllowedComponent } from './module-not-allowed/module-not-allowed.component';
import { NotFoundComponent } from './shared/not-found/not-found.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import localeDe from '@angular/common/locales/de';
import { registerLocaleData } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { environment } from '../environments/environment';

registerLocaleData(localeDe);

export function HttpLoaderFactory(http: HttpClient) {
  const suffix = '.json' + (environment.version && `?t=${environment.version}`);
  return new MultiTranslateHttpLoader(http, [
    { prefix: '/assets/i18n/', suffix },
    { prefix: '/assets/i18n/dashboard/', suffix },
    { prefix: '/assets/i18n/menu-creator/', suffix },
    { prefix: '/assets/i18n/analytics/', suffix },
    { prefix: '/assets/i18n/daily-menu/', suffix },
    { prefix: '/assets/i18n/event-catering/', suffix },
    { prefix: '/assets/i18n/production-plan/', suffix },
    { prefix: '/assets/i18n/erp/', suffix },
    { prefix: '/assets/i18n/sidebar/', suffix },
    { prefix: '/assets/i18n/supplier/', suffix },
    { prefix: '/assets/i18n/settings/', suffix }
  ]);
}

@NgModule({
  declarations: [AppComponent, LoginComponent, ModuleNotAllowedComponent, NotFoundComponent, ResetPasswordComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    NavbarModule,
    ToastrModule.forRoot(),
    DragulaModule.forRoot(),
    ModalModule.forRoot(),
    NgxDaterangepickerMd.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler
      }
    }),
    BrowserAnimationsModule,
    TimepickerModule.forRoot(),
    TooltipModule.forRoot(),
    ReactiveFormsModule,
    FormsModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: GlobalHttpInterceptorService, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
